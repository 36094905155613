import React from "react";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const TransportServiceAreaTwo = () => {
  return (
    <>
     <NavbarOne />
     <SearchPopup />
     <Breadcrumb title={"Terms and Conditions"} />
     <b>Disclaimer:</b> By providing my wireless phone number to Cargo Convoy Inc, I agree and acknowledge that Cargo Convoy Inc. may send text messages to my wireless phone number for any purpose, including marketing purposes.
     <FooterOne />
     <FooterBottomOne />
    </>
  );
};

export default TransportServiceAreaTwo;

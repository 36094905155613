import React, { useState } from "react";
import {
  FaArrowRight,
  FaPhoneAlt,
  FaRegEnvelope,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));



const ServiceDetailsInner = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <NavbarOne />
      <SearchPopup />
      <Breadcrumb title={"Drayage"} />
      {/* service area start */}
      <div className='service-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='service-details-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/drayage.png' alt='Cargo Caonvyo' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-1.png'
                      alt='Cargo Caonvyo'
                    />
                  </div>
                </div>
                <h2>Drayage Services</h2>
                <p>
                Lack of storage, delivery delays, and hindrances due to road jams are some bugging problems in the transportation industry. As a leading logistics provider, we understand the critical role that drayage plays in your supply chain. Cargo Convoy’s innovative drayage solutions tackle this issue by implementing a semi-autonomous platooning mode. This mode optimizes truck routes through mathematical modeling and tabu search heuristic, reducing labor costs through a semi-autonomous truck fleet and saving fuel costs by minimizing air drag.
                </p>
                <p>
                Our digital freight network also brings increased efficiency through automated load matching, improved routes, and real-time planning. It democratizes access to freight and provides transparent insights into the entire shipment lifecycle, thereby reducing costs and enhancing carrier loyalty.
                </p>
                <div className='row'>
                  <div className='col-lg-12 align-self-center'>
                    <h4 className='subtitle'>Our Drayage Services</h4>
                    <ul>
                      <li> <b>Dependable Drayage Solutions:</b> Cargo Convoy ensures smooth and efficient transfers of your containerized goods between different transportation hubs, guaranteeing reliable drayage services.</li>
                      <li> <b>Dependable Port Connectivity:</b> Cargo Convoy ensures reliable port connectivity, facilitating the smooth transition of your cargo from ships to land transport and vice versa. With our drayage services, you can count on efficient container pick-up and delivery, reducing turnaround times and enhancing the overall efficiency of your supply chain.</li>
                      <li> <b>Rail and Distribution Center Support:</b> As a comprehensive 3PL logistics company, we go beyond just port drayage. Our drayage solutions extend to rail yards and distribution centers, offering seamless connections between different modes of transportation. This integration optimizes your cargo flow and reduces transit times.</li>
                      <li> <b>Experienced and Compliant Carriers:</b> We pride ourselves on partnering with experienced and compliant carriers, ensuring that your cargo is in safe hands. Our network of trusted carriers is equipped with the necessary expertise and equipment to handle your goods with care and efficiency.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='sidebar-area'>
                <div className='widget widget_catagory'>
                  <h4 className='widget-title'>
                    OUR SERVICES
                    <span className='dot' />
                  </h4>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/otr'>
                      OTR SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/intermodal'>
                      INTERMODAL SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/drayage'>
                      DRAYAGE SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>

                  </ul>
                </div>

                <div
                  className='widget widget_support text-center mb-0'
                  style={{
                    background: "url(assets/img/widget/support-bg.png)",
                  }}
                >
                  <h4 className='widget-title style-white'>
                    24/7 ONLINE SUPPORT <span className='dot' />
                  </h4>
                  <p>
                    Assertively pontificate high standards in scenarios rather
                    than sustainable system. Interactively empower.
                  </p>
                  <p className='contact'>
                    <FaRegEnvelope />
                    info@cargoconvoy.com
                  </p>
                  <p className='contact mb-0'>
                    <FaPhoneAlt />
                    +1 (267) 513-0604
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h4 className='subtitle'>Why Choose Cargo Convoy for Drayage Services?</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6>Efficiency and Timeliness</h6>
                <p>Our drayage services are tailored to optimize your cargo movements and reduce transit times. With Cargo Convoy as your logistics partner, you can expect on-time and reliable drayage solutions, ensuring the smooth flow of your supply chain.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6>Digital Freight Network</h6>
                <p>Convoy’s digital freight network brings increased efficiency by automating load matching and real-time planning. Shippers and carriers benefit from improved routes, transparent insights, and access to endless capacity, eliminating carbon waste from empty miles and promoting responsible shipping.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6>End-to-End Logistics Support</h6>
                <p>As a full-service 3PL logistics company, we offer comprehensive logistics support. Our drayage solutions seamlessly integrate with our other services, providing you with an end-to-end logistics solution that covers every aspect of your supply chain.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6>Compliance and Safety</h6>
                <p>Cargo Convoy adheres to all regulatory standards and safety protocols. Our commitment to compliance ensures the secure and compliant transportation of your cargo, providing you with peace of mind throughout the drayage process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
      <FooterBottomOne />
      {/* service area end */}
      <ModalVideo
        channel='youtube'
        autoplay
        isOpen={isOpen}
        videoId='XM6kTQPzzpQ'
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ServiceDetailsInner;

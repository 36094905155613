import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import { AiOutlineGlobal } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { Link } from "react-router-dom";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <SearchPopup />
          <NavbarOne />
          <Breadcrumb title={"A Reliable 3PL Logistics Company"} />

          <section className='transportation'>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className='about-inner'>
                     <h2 className='title'>ABOUT US</h2>
                    <div className='section-title mb-0'>
                      <p>At Cargo Convoy, we see ourselves as more than just a company—we are a dedicated partner to businesses seeking efficiency, cost-effectiveness, and sustainability in their logistics operations. Our passion lies in revolutionizing the truck freight industry, driven by our desire to tackle its inherent challenges and create a seamless, optimized experience for both shippers and carriers.</p>
                      <p>The US freight market is a complex puzzle with numerous regional carriers and shippers. Our online platform brings all these pieces together, forming a vast carrier network of small fleets and owner-operators, and connecting them with the demands of over 100,000 shippers. Say goodbye to expensive third-party logistics providers—Cargo Convoy is all about fostering efficient supply and demand matching.</p>                    </div>
                    <div className="contact-btton">
                     <ul>
                      <li> <Link to='/contact'>Discover More</Link></li>
                      <li className="d-flex align-items-center"><FiPhoneCall />
                       <div className="text">
                        <span>Call Anytime</span><br></br>
                        <span className="number"><b>+1 (267) 513-0604</b></span>
                       </div>
                      </li>
                     </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <span className="about"></span>
                  <img className="img-2" src="./assets/img/about-right.png" alt="Cargo Caonvyo"/>
                </div>
              </div>
            </div>
          </section>
          <section className="choose-us" style={{padding: '40px 0'}}>
            <div className="container">
            <div className='section-title mt-lg-0 mt-5 text-center'>
                <h2 className='title'>WHY CHOOSE FOR US?</h2>
                <p>
                At Cargo Convoy, we are committed to delivering excellence in logistics through our global service reach, punctual delivery performance, and round-the-clock customer support.
                </p>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="choose">
                    <AiOutlineGlobal/>
                    <h3>GLOBAL SERVICE</h3>
                    <hr></hr>
                    <p>Experience comprehensive management and coordination of goods, information, and resources across international borders.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="choose">
                    <TbTruckDelivery/>
                    <h3>ON TIME DELIVERY</h3>
                    <hr></hr>
                    <p>Reduce the risk of delays and enhance overall delivery performance with our reliable service.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="choose">
                    <MdOutlineSupportAgent/>
                    <h3>24/7 SUPPORT</h3>
                    <hr></hr>
                    <p>Count on our dedicated team of staff available around the clock to provide you with responsive, 24/7 support.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="our-mission">
            <div className="container">
            <div className='section-title mt-lg-0 mt-5 text-center'>
                <h4 className='subtitle style-2'>OUR GOAL</h4>
                <h2 className='title'>CONSTANT INNOVATION</h2>
              </div>
                <div className="row">
                  <div className="col-md-4">
                    <div id="box">
                    <h4>Mission</h4>
                    <h6>We offer customer-oriented logistics solutions.</h6>
                    <p>
                    Our mission is to become the No. 1 and most trusted 3PL solutions provider. We are dedicated to providing on-time and safe deliveries, with a primary focus on customer satisfaction. By leveraging advanced technology and our team's expertise, we aim to be your one-stop solution for all your logistics needs and requirements.
                    </p>
                  </div>  
                  </div>
                  <div className="col-md-4">
                    <div id="box">
                    <h4>Vision</h4>
                    <h6>Transporting Quality to Enhance Your Business.</h6>
                    <p>
                    At Cargo Convoy, we envision becoming the holistic gateway for all your logistics needs. Our dedicated team is committed to offering innovative, efficient, and customized digital solutions to our clients. With trained and experienced professionals, we consistently deliver best-in-class services to enhance your business.
                    </p>
                  </div>  
                  </div>
                  <div className="col-md-4">
                    <div id="box">
                    <h4>History</h4>
                    <h6>Logistics Business - Supplier of Transport Solutions.</h6>
                    <p>
                    Cargo Convoy Inc. was founded in 2023 with a commitment to creating innovative solutions for logistics challenges. We pride ourselves on offering cutting-edge technology and exceptional customer service. Our goal is to provide the most effective and practical end-to-end logistics solutions.
                    </p>
                  </div>  
                  </div>
                </div>   
            </div>
          </section>
          <FooterOne />
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;

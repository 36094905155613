import React from "react";
import { Link } from "react-router-dom";
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));



const TeamOne = () => {

  return (
    <>
    <NavbarOne />
    <SearchPopup />
    <Breadcrumb title={"Privacy Policy"} />
     <section className="policy">
      <div className="container">
        <div className="row">
          <div className="content-policy pt-4 pb-4">
            <h5>PRIVACY POLICY</h5>
            <span>Effective Date: June 19, 2022</span>
            <p>Thank you for visiting <Link to="/">www.cargoconvoy.us</Link>. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>
            <p>Please read this policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the website.</p>
            <h5>INFORMATION WE COLLECT</h5>
            <p>We may collect personal information that you provide directly to us when you interact with the Website. This information may include:</p>
            <ul>
              <li><p>Contact information, such as name, email address, mailing address, and phone number.</p></li>
              <li><p>Payment information (if applicable), such as credit card details.</p></li>
              <li><p>Information you voluntarily provide to us, such as when you leave comments, participate in surveys, or interact with our customer support.</p></li>
            </ul>
            <h5>HOW WE USE YOUR INFORMATION</h5>
            <p>We may use the information we collect in the following ways:</p>
            <ul>
              <li><p>To operate, maintain, and improve our website and services.</p></li>
              <li><p>To respond to your inquiries, questions, and comments.</p></li>
              <li><p>To send you promotional emails about our products and services (you may opt out at any time).</p></li>
              <li><p>To process your transactions and fulfill your orders.</p></li>
              <li><p>To comply with legal obligations.</p></li>
            </ul>
            <h5>SHARING YOUR INFORMATION</h5>
            <p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, provided those parties agree to keep this information confidential. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</p>
            <h5>CONSENT TO SMS MESSAGING</h5>
            <p>By providing a telephone number and submitting any forms such as "Contact Us" or "Get a Quote" on our website, you are consenting to be contacted by SMS text message. Message and Data Rates may apply. You can reply STOP to opt-out of further messaging. For more help, you can send HELP. Our Privacy Policy is located at [link to your privacy policy page].</p>
            <h5>SECURITY OF YOUR INFORMATION</h5>
            <p>We use reasonable measures to maintain the security of your personal information. However, no data transmission over the Internet or method of electronic storage is completely secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
            <div className="text-left">
              <h5>CONTACT US</h5>
              <p>If you have any questions about this Privacy Policy, please contact us at <Link to='/' style={{color: '#1b9eff'}}><b>info@cargoconvoy.co</b></Link></p>
            </div>
          </div>
        </div>
      </div>
     </section>
     <FooterOne />
      <FooterBottomOne />
    </>
  );
};

export default TeamOne;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeOne from "./pages/HomeOne";
import AOS from "aos";
import "aos/dist/aos.css";
import { Suspense, useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import About from "./pages/About";
import Service from "./pages/Service";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Pricing from "./pages/Pricing";
import Faq from "./pages/Faq";
import TeamOne from "./components/TeamOne";
import Contact from "./pages/Contact";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import OtrServiceDetails from "./components/OtrServiceDetails";
import IntermodalService from "./components/IntermodalService";
import DrayageService from "./components/DrayageService";
import TermConditions from "./components/TermConditions";
import WhyChooseUsOne from "./components/WhyChooseUsOne";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <RouteScrollToTop />
     <Suspense fallback={<div>Loading...</div>}>
     <Routes>
        <Route exact path='/' element={<HomeOne />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/services' element={<Service />} />
        <Route exact path='/drayage' element={<DrayageService />} />
        <Route exact path='/otr' element={<OtrServiceDetails />} />
        <Route exact path='/intermodal' element={<IntermodalService />} />
        <Route exact path='/our-team' element={<TeamOne />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/blog-details' element={<BlogDetails />} />
        <Route exact path='/pricing' element={<Pricing />} />
        <Route exact path='/faq' element={<Faq />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/term-conditions' element={<TermConditions />} />
        <Route exact path='/why-choose-us' element={<WhyChooseUsOne />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
     </Suspense>
      <ScrollToTop smooth color='#a6ce3a' />
    </BrowserRouter>
  );
}

export default App;

import React, { useState } from "react";
import {
  FaArrowRight,
  FaPhoneAlt,
  FaRegEnvelope,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));


const ServiceDetailsInner = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <NavbarOne />
      <SearchPopup />
      <Breadcrumb title={"Intermodal"} />
      {/* service area start */}
      <div className='service-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='service-details-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/intermodal.png' alt='Cargo Caonvyo' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-1.png'
                      alt='Cargo Caonvyo'
                    />
                  </div>
                </div>
                <h2>INTERMODAL SERVICES</h2>
                <p>
                Cargo Convoy Inc. stands at the forefront of intermodal logistics, offering a comprehensive array of services that seamlessly integrate road, rail, sea, and air freight. Our commitment to excellence ensures efficient and cost-effective solutions tailored to meet your specific logistics needs. With innovative technology and strategic partnerships.
                </p>
                <p>
                Cargo Convoy Inc. delivers unmatched reliability and operational excellence in every aspect of intermodal transportation. Trust us to optimize your supply chain and enhance distribution capabilities with personalized service and a dedication to exceeding expectations.
                </p>
                <div className='row'>
                  <div className='col-lg-12 align-self-center'>
                    <h4 className='subtitle'>Our Intermodal Services</h4>
                    <ul>
                      <li> <b>Versatility and Integration:</b> We seamlessly integrate road, rail, sea, and air freight for comprehensive logistics solutions, accommodating various cargo types and destinations with optimal efficiency.</li>
                      <li> <b>Efficiency and Cost-Effectiveness:</b> Our strategic planning minimizes costs by optimizing transport routes, reducing environmental impact, and maximizing operational efficiency across all modes of transport.</li>
                      <li> <b>Reliability and Security:</b> With a focus on secure handling and strict adherence to delivery schedules, we ensure the safe and timely transportation of goods through robust tracking and monitoring systems.</li>
                      <li> <b>Customization and Tailored Solutions:</b> Tailoring services to meet specific client needs, we provide scalable solutions for complex supply chains, specialized cargo handling, and flexible delivery requirements.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='sidebar-area'>
                <div className='widget widget_catagory'>
                  <h4 className='widget-title'>
                  OUR SERVICES
                    <span className='dot' />
                  </h4>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/otr'>
                      OTR SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/intermodal'>
                      INTERMODAL SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/drayage'>
                      DRAYAGE SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>

                  </ul>
                </div>
                <div
                  className='widget widget_support text-center mb-0'
                  style={{
                    background: "url(assets/img/widget/support-bg.png)",
                  }}
                >
                  <h4 className='widget-title style-white'>
                    24/7 ONLINE SUPPORT <span className='dot' />
                  </h4>
                  <p>
                    Assertively pontificate high standards in scenarios rather
                    than sustainable system. Interactively empower.
                  </p>
                  <p className='contact'>
                    <FaRegEnvelope />
                    info@cargoconvoy.com
                  </p>
                  <p className='contact mb-0'>
                    <FaPhoneAlt />
                    +1 (267) 513-0604
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h4 className='subtitle'>Why Choose Cargo Convoy for Intermodal Services?</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6> Global Network and Expertise:</h6>
                <p>Benefit from our extensive global network and deep industry expertise, ensuring smooth logistics operations across international borders and diverse markets.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6>Innovative Technology</h6>
                <p>Leverage cutting-edge technology and digital solutions for real-time visibility, predictive analytics, and continuous improvement in logistics performance.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6>Customer-Centric Approach</h6>
                <p>Our dedicated team provides exceptional customer service, collaborating closely with you to understand your unique challenges and deliver tailored solutions that exceed expectations.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="service-choose-box">
                <h6>Operational Excellence</h6>
                <p>We uphold high standards of operational excellence, ensuring streamlined processes, proactive problem-solving, and consistent delivery of superior intermodal services.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
      <FooterBottomOne />
      {/* service area end */}
      <ModalVideo
        channel='youtube'
        autoplay
        isOpen={isOpen}
        videoId='XM6kTQPzzpQ'
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ServiceDetailsInner;

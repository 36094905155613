import React, { useState } from "react";
import {
  FaArrowRight,
  FaPhoneAlt,
  FaRegEnvelope,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));



const ServiceDetailsInner = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
     <NavbarOne />
     <SearchPopup />
    <Breadcrumb title={"OTR"} />
      {/* service area start */}
      <div className='service-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='service-details-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/7.png' alt='Cargo Caonvyo' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-1.png'
                      alt='Cargo Caonvyo'
                    />
                  </div>
                </div>
                <h2>OTR SERVICES</h2>
                <p>
                Cargo Convoy is a leading 3PL logistics company renowned for its exceptional Over-the-Road (OTR) services. We take immense pride in offering top-notch Over-the-Road (OTR) services and providing efficient and reliable transportation solutions. As a trusted third-party logistics provider, we excel in streamlining goods movement over the road.
                </p>
                <p>
                We are meeting the diverse cargo needs of businesses across US every day and providing seamless deliveries. Our continuous effort and on-time deliveries make us the preferred choice for companies seeking a partner they can trust for their supply chain requirements.
                </p>
                <div className='row'>
                  <div className='col-lg-12 align-self-center'>
                    <h4 className='subtitle'>Our OTR Services</h4>
                    <ul>
                      <li> <b>Nationwide Coverage:</b> At Cargo Convoy, we boast an extensive network of carriers strategically positioned to provide seamless nationwide coverage for your cargo. From bustling metropolitan cities to remote regions, our services extend to every corner of US. No destination is too far or too challenging for us to reach.</li>
                      <li> <b>Temperature-Controlled Freight Handling:</b> With our specialized expertise in time-sensitive and perishable freight, our dedicated team ensures prompt delivery while meticulously maintaining the required temperature, safeguarding your cargo’s integrity throughout its journey.</li>
                      <li> <b>Deal Standardization Service:</b> Our cutting-edge Deal Standardization Service optimizes the assimilation of critical contractual-level data across various systems. With improved data management and smart workflows, it facilitates informed decision-making, automated pricing, and advanced lane-level analytics, elevating supply chain efficiency.</li>
                      <li> <b>Dedicated Customer Support:</b> Your satisfaction is our priority. At Cargo Convoy, we are committed to providing a superior customer experience. Our customer-centric approach ensures dedicated support 24/7. Our team of logistics experts is readily available to assist you at every step, making your experience with us hassle-free and pleasant.</li>
                    </ul>
                  </div>
                </div> 
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='sidebar-area'>
                <div className='widget widget_catagory'>
                  <h4 className='widget-title'>
                  OUR SERVICES
                    <span className='dot' />
                  </h4>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/otr'>
                      OTR SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/intermodal'>
                      INTERMODAL SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/drayage'>
                      DRAYAGE SERVICES{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>

                  </ul>
                </div>
                <div
                  className='widget widget_support text-center mb-0'
                  style={{
                    background: "url(assets/img/widget/support-bg.png)",
                  }}
                >
                  <h4 className='widget-title style-white'>
                    24/7 ONLINE SUPPORT <span className='dot' />
                  </h4>
                  <p>
                    Assertively pontificate high standards in scenarios rather
                    than sustainable system. Interactively empower.
                  </p>
                  <p className='contact'>
                    <FaRegEnvelope />
                    info@cargoconvoy.com
                  </p>
                  <p className='contact mb-0'>
                    <FaPhoneAlt />
                    +1 (267) 513-0604
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h4 className='subtitle'>Why Choose Cargo Convoy for OTR Services?</h4>
          <p>At Cargo Convoy, we pride ourselves on being more than just a freight forwarding company. Our expertise as a 3PL logistics company and supply chain management company ensures:</p>
          <div className="row">
            <div className="col-md-4">
              <div className="service-choose-box">
                <h6>Reliability and Efficiency</h6>
                <p>As a trusted third-party logistics provider, we understand the significance of on-time deliveries and efficient goods movement. With Cargo Convoy, you can count on reliable and punctual services, ensuring your cargo reaches its destination as planned every time.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-choose-box">
                <h6>Cost-effectiveness</h6>
                <p>At Cargo Convoy, we understand that managing logistics costs is crucial for your business. As a 3PL logistics company, we offer competitive pricing without compromising the quality of service. Our cost-effective solutions help you optimize expenses and maximize the value of your logistics investments.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-choose-box">
                <h6>Compliance and Safety</h6>
                <p>The safety of your cargo is our paramount concern. Cargo Convoy complies with all regulations and industry standards, implementing rigorous safety measures throughout the transportation process to ensure secure deliveries. You can trust us to handle your cargo with the utmost care and professionalism.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
      <FooterBottomOne />

      {/* service area end */}
      <ModalVideo
        channel='youtube'
        autoplay
        isOpen={isOpen}
        videoId='XM6kTQPzzpQ'
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ServiceDetailsInner;

import React, { useState } from "react";
import ModalVideo from "react-modal-video";
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const WhyChooseUsOne = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
    <NavbarOne />
    <SearchPopup />
    <Breadcrumb title={"Why Choose Us"} />
      <section className="choose-us">
        <div className="container">
            <div className='section-title style-white mb-0 text-center'>
              <h4 className='subtitle style-2 text-black'>Why Choose Cargo Convoy INC</h4>
            </div>
            <div className="row">
              <div className="col-md-4">
                <a className="card1" href="#">
                    <h3>Expertise in 3PL Logistics</h3>
                    <p className="small">At Cargo Convoy Inc., our team boasts extensive experience in third-party logistics (3PL). We are adept at managing complex supply chains, ensuring that every aspect of your logistics is handled with precision and professionalism. This expertise translates into efficient, reliable solutions that optimize your supply chain and meet your unique business needs.</p>
                   <div className="go-corner" href="#">
                      <div className="go-arrow">
                       →
                       </div>
                    </div>
                </a>
              </div>
              <div className="col-md-4">
                <a className="card1" href="#">
                    <h3>Customized Solutions</h3>
                    <p className="small">We understand that every business is different. That’s why we offer customized logistics strategies designed specifically for your operations. Whether you need tailored warehousing, transportation, or inventory management solutions, we work closely with you to create a plan that enhances efficiency and reduces costs.</p>
                   <div className="go-corner" href="#">
                      <div className="go-arrow">
                       →
                       </div>
                    </div>
                </a>
              </div>
              <div className="col-md-4">
                <a className="card1" href="#">
                    <h3>Cutting-Edge Technology</h3>
                    <p className="small">Leveraging the latest in logistics technology, Cargo Convoy Inc. provides real-time tracking, advanced data analytics, and streamlined processes. Our state-of-the-art systems ensure that you have full visibility into your supply chain, allowing you to make informed decisions and respond quickly to any changes.</p>
                   <div className="go-corner" href="#">
                      <div className="go-arrow">
                       →
                       </div>
                    </div>
                </a>
              </div>
              <div className="col-md-4">
                <a className="card1" href="#">
                    <h3>Commitment to Customer Service</h3>
                    <p className="small">Customer satisfaction is at the heart of everything we do. Our dedicated support team is available 24/7 to address any concerns, answer your questions, and provide personalized assistance. We prioritize clear communication and responsiveness to ensure a smooth and seamless experience for our clients.</p>
                   <div className="go-corner" href="#">
                      <div className="go-arrow">
                       →
                       </div>
                    </div>
                </a>
              </div>
              <div className="col-md-4">
                <a className="card1" href="#">
                    <h3>Proven Track Record</h3>
                    <p className="small">With a solid history of successful partnerships and a strong industry reputation, Cargo Convoy Inc. delivers consistent, high-quality service. Our proven track record demonstrates our ability to meet and exceed client expectations, providing reliable logistics solutions that contribute to your business success.</p>
                   <div className="go-corner" href="#">
                      <div className="go-arrow">
                       →
                       </div>
                    </div>
                </a>
              </div>
              <div className="col-md-4">
                <a className="card1" href="#">
                    <h3>Global Reach with Local Expertise</h3>
                    <p className="small">Cargo Convoy Inc. combines a vast network of global connections with deep local knowledge. This blend of international reach and local expertise enables us to offer comprehensive logistics solutions that cover both domestic and international needs, ensuring that your goods are managed effectively, no matter where they’re going.</p>
                   <div className="go-corner" href="#">
                      <div className="go-arrow">
                       →
                       </div>
                    </div>
                </a>
              </div>
            </div>
        </div>
      </section>
      {/*wcu-area end*/}
      <FooterOne />
      <FooterBottomOne />
      <ModalVideo 
        channel='youtube'
        autoplay
        isOpen={isOpen}
        videoId='XM6kTQPzzpQ'
        onClose={() => setOpen(false)}
      />
   
    </>
  );
};

export default WhyChooseUsOne;

import React from "react";

const FooterOne = React.lazy(() => import("../components/FooterOne"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));  


const TeamOne = () => {


  return (
    <>
    <NavbarOne />
    <SearchPopup />
    <Breadcrumb title={"Our Team Members"} />
    <section className="our-team">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="content">
              <h3>AMRENDRA KUMAR <span className="ceo">CEO</span></h3>
              
              <p>With over a decade of experience in the supply chain and logistics sector, I lead a team of professionals who provide innovative and customized logistics and IT solutions for clients across various industries. As the CEO of Geesha Solutions Private Limited and Cargo Convoy INC, I oversee the vision, strategy, and execution of both companies, ensuring growth and profitability.</p>
              <p>My core competencies include customer relationship management, people management, and business development. I have successfully founded and co-founded multiple companies in the field, creating value and impact in the industry. I also serve as the Director of Operations at Eternity Logistics Solutions Pt Ltd, where I manage the planning and implementation of the company’s operations. My mission is to make a lasting difference in the logistics and IT industry and create solutions for the future.</p>
            </div>
          </div>
          <div className="col-md-5">
          <img src='assets/img/team/owner.png' alt='Cargo Caonvyo' />
          </div>
        </div>
      </div>
    </section>
    <section className="our-team">
      <div className="container">
        <div className="row">
        <div className="col-md-5">
          <img src='assets/img/team/cfo.png' alt='Cargo Caonvyo' />
          </div>
          <div className="col-md-7">
            <div className="content">
              <h3>RAJINDER SINGH <span className="ceo">CFO</span></h3>
              <p>I am Rajinder Singh, and I bring with me a wealth of experience spanning seven years in the IT industry. Throughout my career, I have consistently demonstrated my leadership abilities by spearheading cross-functional teams in ever-changing and dynamic environments. My adaptability to evolving industry standards and emerging technologies has been a cornerstone of my professional journey.</p>
              <p>My passion lies in harnessing the power of technology to tackle intricate challenges and steer businesses towards success. I am particularly excited about the prospect of applying my extensive IT expertise to the field of logistics.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <section className="our-member mb-5">
      <h3 className="mb-4 text-center">Our Team</h3>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="text-center">
              <img src="assets/img/team/02.png" alt="" />
              <h5>Aditya Chandra</h5>
              <h6>(Human Resources Manager)</h6>
            </div>
          </div>
          <div className="col-md-3">
            <div className="text-center">
              <img src="assets/img/team/01.png" alt="" />
              <h5>Adam Smith</h5>
              <h6>(Accounts & Compliance Head)</h6>
            </div>
          </div>
          <div className="col-md-3">
            <div className="text-center">
              <img src="assets/img/team/03.png" alt="" />
              <h5>Scott Smith</h5>
              <h6>(Accounts Payable)</h6>
            </div>
          </div>
          <div className="col-md-3">
            <div className="text-center">
              <img src="assets/img/team/04.png" alt="" />
              <h5>Dan Edwards</h5>
              <h6>(IT Manager)</h6>
            </div>
          </div>
        </div>
      </div>
    </section> */}

      <FooterOne />
      <FooterBottomOne />
    </>
  );
};

export default TeamOne;
